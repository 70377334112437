import React, { Component } from 'react';
import { StandardPageProps } from '../utils/Props';
import IndexPage from './index';

export default class NotFoundPage extends Component<NotFoundPageProps> {
  render() {
    return <IndexPage {...this.props} />;
  }
}

interface NotFoundPageProps extends StandardPageProps {}
